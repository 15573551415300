import cx from "classnames"

import { TableComponentTypes } from "./types"

const TableHeadRow = ({ className, children }: TableComponentTypes) => (
  <thead
    className={cx(
      "text-dusk-500 text-sm text-left leading-4 tracking-[0.01em]",
      className
    )}
  >
    <tr>{children}</tr>
  </thead>
)

export default TableHeadRow
