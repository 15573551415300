import { useQuery } from "@tanstack/react-query"
import { useDisclosureState, DisclosureContent } from "reakit/Disclosure"
import cx from "classnames"

import NavBarInboxLink from "../../sections/Inbox/NavBarInboxLink"
import NavBarBrand from "./NavBarBrand"
import NavBarLinks from "./NavBarLinks"
import NavBarHelpLink from "./NavBarHelpLink"
import SlideInNav from "./SlideInNav"
import UserMenu from "./UserMenu"
import NavBarMenuToggle from "./NavBarMenuToggle"
import { useHandleInaccessibleAccount, useNavLinks } from "./helpers"
import useIsOnboarding from "../../hooks/useIsOnboarding"
import useIsEmailVerified from "../../hooks/useIsEmailVerified"
import { useAccessToken } from "../../stores"
import { NavBarTypes } from "./types"

const NavBar = ({ isPartnersNav = false }: NavBarTypes) => {
  const accessToken = useAccessToken()
  const disclosure = useDisclosureState({ animated: true })
  const isOnboarding = useIsOnboarding()
  const { topbarLinks } = useNavLinks(isPartnersNav)
  const isEmailVerified = useIsEmailVerified()
  const handleInaccessibleAccount = useHandleInaccessibleAccount()

  const isLoggedIn = !!accessToken
  const showLinks = isEmailVerified && !isOnboarding

  const { data: isError404 } = useQuery({
    queryKey: ["is-error-404"],
    initialData: false,
  })

  if (!isLoggedIn && !isError404) {
    return null
  }

  return (
    <>
      <DisclosureContent
        {...disclosure}
        className="group fixed inset-0 z-2147483641 pointer-events-none h-screen md:hidden"
      >
        <SlideInNav disclosure={disclosure} isPartnersNav={isPartnersNav} />
      </DisclosureContent>

      <nav
        className={cx(
          "sticky md:relative top-0 md:top-auto bg-charcoal-500 shadow-sm shrink-0 md:z-auto",
          isEmailVerified ? "z-10 " : "z-2147483647"
        )}
      >
        <div className="relative px-5 mx-auto flex items-center justify-between h-16">
          <NavBarMenuToggle
            showLinks={showLinks}
            disclosure={disclosure}
            topbarLinks={topbarLinks}
          />

          <NavBarBrand
            isPartnersNav={isPartnersNav}
            onClick={handleInaccessibleAccount}
          />

          <div className="flex flex-row items-center text-lg leading-[1.50] tracking-[0.02em]">
            <ul className="hidden text-white ml-10 md:flex md:items-center">
              <NavBarLinks
                showLinks={showLinks}
                topbarLinks={topbarLinks}
                onClick={handleInaccessibleAccount}
              />

              <NavBarInboxLink
                isPartnersNav={isPartnersNav}
                onClick={handleInaccessibleAccount}
                showLinks={showLinks}
              />

              <NavBarHelpLink />
            </ul>

            <NavBarInboxLink
              isPartnersNav={isPartnersNav}
              className="md:hidden"
              onClick={handleInaccessibleAccount}
              showLinks={showLinks}
            />

            <UserMenu isPartnersNav={isPartnersNav} />
          </div>
        </div>
      </nav>
    </>
  )
}

export default NavBar
