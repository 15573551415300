import { useEffect } from "react"
import { Link } from "react-router"
import { DisclosureStateReturn } from "reakit/Disclosure"
import cx from "classnames"

import NCXLogo from "../../images/NCX---Logo---Full---White.png"
import SlideInNavLinks from "./SlideInNavLinks"
import SlideInNavClose from "./SlideInNavClose"

export interface SlideInNavTypes {
  disclosure: DisclosureStateReturn
  isPartnersNav: boolean
}

const SlideInNav = ({ disclosure, isPartnersNav }: SlideInNavTypes) => {
  useEffect(() => {
    if (!disclosure.visible) return

    // prevent page scroll when navigation is open
    document.body.classList.add("overflow-y-hidden")

    return () => {
      document.body.classList.remove("overflow-y-hidden")
    }
  }, [disclosure.visible])

  return (
    <>
      <button
        role="button"
        onClick={disclosure.hide}
        className={cx(
          "fixed inset-0 z-2147483641 pointer-events-none opacity-0 transition-opacity duration-200 ease-in-out",
          "group-data-enter:pointer-events-auto group-data-enter:bg-gray-500/75 group-data-enter:opacity-100"
        )}
        aria-label="slide-in-nav-overlay"
      />

      <div
        className={cx(
          "h-screen w-[290px] bg-charcoal-500 flex flex-col fixed inset-0 right-auto z-2147483641 pointer-events-auto pt-6 pb-9 px-5",
          "transition-transform duration-30 ease-in-out group-data-leave:-translate-x-[290px] group-data-enter:translate-x-0"
        )}
      >
        <SlideInNavClose disclosure={disclosure} />

        <div className="flex flex-col items-center justify-between w-full grow">
          <SlideInNavLinks
            isPartnersNav={isPartnersNav}
            disclosure={disclosure}
          />

          <div className="flex items-center justify-center">
            <Link
              aria-label="Go to dashboard"
              to="/"
              className="p-2"
              onClick={disclosure.hide}
            >
              <img alt="NCX Logo" className="h-[2.75rem]" src={NCXLogo} />
            </Link>
          </div>
        </div>
      </div>
    </>
  )
}

export default SlideInNav
