import { RefObject, useEffect, useRef, useState } from "react"
import { Navigate, Route, useLocation } from "react-router"

import {
  SentryRoutes,
  ProtectedRoute,
  AccountComponent,
  SharedAuth,
  Redirect,
} from "../App"
import NavLayout from "../pages/_layouts/NavLayout"
import Logout from "../pages/Logout"
import GettingStarted from "../sections/GettingStarted"
import ProSignUp from "../pages/ProSignUp"
import ResetPasswordConfirm from "../pages/ResetPasswordConfirm"
import AccountInfo from "../pages/accounts/AccountInfo"
import AskAQuestion from "../pages/AskAQuestion"
import OnboardingPropertyBoundaries from "../pages/onboarding/PropertyBoundaries"
import OnboardingSurvey from "../pages/onboarding/OnboardingSurvey"
import OnboardingOverview from "../pages/onboarding/OnboardingOverview"
import ProjectListing from "../pages/ProjectListing"
import ProjectDetails from "../pages/ProjectDetails"
import Threats from "../pages/Threats"
import BrowsedAll from "../pages/BrowsedAll"
import SettingsAccountInfo from "../pages/settings/AccountInfo"
import SettingsContactInfo from "../pages/settings/ContactInfo"
import SettingsProjectInterest from "../pages/settings/ProjectInterest"
import SettingsChangePassword from "../pages/settings/ChangePassword"
import SubscriptionManagement from "../pages/settings/SubscriptionManagement"
import SettingsPropertyDetails from "../pages/settings/PropertyDetails"
import SettingsPropertyBoundaries from "../pages/settings/PropertyBoundaries"
import SettingsUsers from "../pages/settings/Users"
import ViewAccount from "../pages/accounts/ViewAccount"
import Accounts from "../pages/Accounts"
import Inbox from "../pages/Inbox"
import BecomeAMember from "../pages/BecomeAMember"
import Error404 from "../pages/Error404"
import EligibilityQuiz from "../pages/EligibilityQuiz"
import TeaserMap from "../pages/TeaserMap"
import AdvisorSignup from "../pages/AdvisorSignup"
import BookConsultation from "../pages/BookConsultation"
import SPBWizard from "../pages/SPBWizard"
import Sylvia from "../pages/Sylvia"
import { useIsMultiAccount } from "../hooks"
import { useSessionStore } from "../stores"
import { AuthDataTypes, SignUpDataTypes } from "../types/auth"

const barkAPIRootURL = import.meta.env.VITE_APP_BARK_API_ROOT_URL

export const LandOwnersRouter = () => {
  const location = useLocation()
  const isMultiAccount = useIsMultiAccount()
  const [authData, setAuthData] = useState<AuthDataTypes | SignUpDataTypes>({})
  const ncxLogoRef = useRef<HTMLAnchorElement>(null)

  const setNcxLogoRef = useSessionStore((state) => state.setNcxLogoRef)

  useEffect(() => {
    setNcxLogoRef(ncxLogoRef as unknown as RefObject<HTMLAnchorElement>)
  }, [ncxLogoRef, setNcxLogoRef])

  const bgClass =
    location.pathname === "/pro-sign-up" ? "bg-pro-signup-img" : ""

  return (
    <SentryRoutes>
      <Route path="/view-assessments" element={<TeaserMap />} />

      <Route element={<NavLayout bgClass={bgClass} />}>
        <Route
          path="/create-account"
          // Lint ignore: Since this is a one-off Redirect, we don't care about virtual DOM constructor preservation
          element={
            <Navigate
              replace
              to={{ ...location, pathname: "/getting-started" }}
              state={{ authStep: "signUp" }}
            />
          }
        />

        <Route element={<SharedAuth />}>
          <Route
            path="/sign-up"
            element={
              // we have instances of /sign-up out in the world so we preserve direct link to sign-up page
              <Navigate
                replace
                to={{ ...location, pathname: "/getting-started" }}
                state={{ authStep: "signUp" }}
              />
            }
          />
          <Route
            path="/vip"
            element={
              <Navigate
                replace
                to={{ ...location, pathname: "/getting-started" }}
              />
            }
          />
          <Route
            path="/login"
            element={
              <Navigate
                replace
                to={{ ...location, pathname: "/getting-started" }}
              />
            }
          />
          <Route
            path="/reset-password"
            element={
              <Navigate
                replace
                to={{ ...location, pathname: "/getting-started" }}
                state={{ authStep: "resetPassword" }}
              />
            }
          />
          <Route
            path="/getting-started"
            element={
              <GettingStarted authData={authData} setAuthData={setAuthData} />
            }
          />
          <Route
            path="/pro-sign-up"
            element={
              <ProSignUp authData={authData} setAuthData={setAuthData} />
            }
          />
        </Route>

        <Route path="/logout" element={<Logout />} />
        <Route
          path="/reset-password-confirm"
          element={<ResetPasswordConfirm />}
        />

        <Route element={<ProtectedRoute />}>
          {/* Personal settings routes */}
          <Route
            path="/settings"
            element={<Navigate replace to="/settings/contact-info" />}
          />
          <Route
            path="/settings/project-interest"
            element={<Navigate replace to="/settings/program-interest" />}
          />
          <Route
            path="/settings/change-password"
            element={<SettingsChangePassword />}
          />
          <Route
            path="/settings/contact-info"
            element={<SettingsContactInfo />}
          />

          <Route
            path="/settings/program-interest"
            element={<SettingsProjectInterest />}
          />

          {/* Account settings routes */}
          <Route
            path="/accounts/:accountId/settings"
            element={
              <Redirect to="/accounts/:accountId/settings/account-info" />
            }
          />
          <Route
            path="/accounts/:accountId/settings/account-info"
            element={<SettingsAccountInfo />}
          />
          {/* ASANA: If this id ever changes, ensure Tipalti also gets updated as well, since we're referencing this specific URL in a Tipalti email template. */}
          {/* https://app.asana.com/0/1198952737412966/1203482757456891/f */}
          <Route
            path="/settings/property-details"
            element={<AccountComponent component={SettingsPropertyDetails} />}
          />
          <Route
            path="/accounts/:accountId/settings/property-details"
            element={<AccountComponent component={SettingsPropertyDetails} />}
          />
          <Route
            path="/settings/subscription-management"
            element={<AccountComponent component={SubscriptionManagement} />}
          />
          <Route
            path="/accounts/:accountId/settings/subscription-management"
            element={<AccountComponent component={SubscriptionManagement} />}
          />
          <Route
            path="/settings/property-boundaries"
            element={
              <AccountComponent component={SettingsPropertyBoundaries} />
            }
          />
          <Route
            path="/accounts/:accountId/settings/property-boundaries"
            element={
              <AccountComponent component={SettingsPropertyBoundaries} />
            }
          />
          <Route
            path="/settings/users"
            element={<AccountComponent component={SettingsUsers} />}
          />
          <Route
            path="/accounts/:accountId/settings/users"
            element={<AccountComponent component={SettingsUsers} />}
          />

          <Route
            path="/onboarding"
            element={<Navigate replace to="/onboarding/property-boundaries" />}
          />
          <Route
            path="/accounts/:accountId/onboarding"
            element={
              <Redirect to="/accounts/:accountId/onboarding/account-info" />
            }
          />
          <Route
            path="/accounts/new"
            element={
              <AccountComponent allowMissingId={true} component={AccountInfo} />
            }
          />
          <Route
            path="/accounts/:accountId/onboarding/account-info"
            element={
              <AccountComponent allowMissingId={true} component={AccountInfo} />
            }
          />
          <Route
            path="/onboarding/property-boundaries"
            element={
              <AccountComponent component={OnboardingPropertyBoundaries} />
            }
          />
          <Route
            path="/accounts/:accountId/onboarding/property-boundaries"
            element={
              <AccountComponent component={OnboardingPropertyBoundaries} />
            }
          />
          <Route
            path="/onboarding/goals"
            element={<AccountComponent component={OnboardingSurvey} />}
          />
          <Route
            path="/accounts/:accountId/onboarding/goals"
            element={<AccountComponent component={OnboardingSurvey} />}
          />
          <Route
            path="/onboarding/overview"
            element={<AccountComponent component={OnboardingOverview} />}
          />
          <Route
            path="/accounts/:accountId/onboarding/overview"
            element={<AccountComponent component={OnboardingOverview} />}
          />
          <Route
            path="/ask-a-question"
            element={<AccountComponent component={AskAQuestion} />}
          />
          <Route
            path="/accounts/:accountId/ask-a-question"
            element={<AccountComponent component={AskAQuestion} />}
          />

          {/* Projects routes */}
          <Route
            path="/projects"
            element={<Navigate replace to="/programs/page/1" />}
          />
          <Route
            path="/programs"
            element={<Navigate replace to="/programs/page/1" />}
          />
          <Route
            path="/accounts/:accountId/projects"
            element={
              <Redirect
                to={
                  isMultiAccount
                    ? "/accounts/:accountId/programs/page/1"
                    : "/programs/page/1"
                }
              />
            }
          />
          <Route
            path="/accounts/:accountId/programs"
            element={
              <Redirect
                to={
                  isMultiAccount
                    ? "/accounts/:accountId/programs/page/1"
                    : "/programs/page/1"
                }
              />
            }
          />
          <Route
            path="/projects/page/:pageId"
            element={<Redirect to="/programs/page/:pageId" />}
          />
          <Route
            path="/accounts/:accountId/projects/page/:pageId"
            element={
              <Redirect
                to={
                  isMultiAccount
                    ? "/accounts/:accountId/programs/page/:pageId"
                    : "/programs/page/1"
                }
              />
            }
          />
          <Route
            path="/projects/browsed-all"
            element={<Navigate replace to="/programs/browsed-all" />}
          />
          <Route
            path="/accounts/:accountId/projects/browsed-all"
            element={
              <Redirect
                to={
                  isMultiAccount
                    ? "/accounts/:accountId/programs/browsed-all"
                    : "/programs/browsed-all"
                }
              />
            }
          />
          <Route
            path="/projects/:projectId"
            element={<Redirect to="/programs/:projectId" />}
          />
          <Route
            path="/accounts/:accountId/projects/:projectId"
            element={
              <Redirect
                to={
                  isMultiAccount
                    ? "/accounts/:accountId/programs/:projectId"
                    : "/programs/:projectId"
                }
              />
            }
          />

          <Route
            path="/programs/page/:pageId"
            element={
              <AccountComponent
                component={ProjectListing}
                redirectPath="/accounts"
              />
            }
          />
          <Route
            path="/accounts/:accountId/programs/page/:pageId"
            element={
              <AccountComponent
                component={ProjectListing}
                redirectPath="/accounts"
              />
            }
          />
          <Route path="/programs/browsed-all" element={<BrowsedAll />} />
          <Route
            path="/accounts/:accountId/programs/browsed-all"
            element={<BrowsedAll />}
          />
          <Route
            path="/programs/:projectId"
            element={
              <AccountComponent
                component={ProjectDetails}
                redirectPath="/accounts"
              />
            }
          />
          <Route
            path="/accounts/:accountId/programs/:projectId"
            element={
              <AccountComponent
                component={ProjectDetails}
                redirectPath="/accounts"
              />
            }
          />

          <Route
            path="/threats"
            element={<AccountComponent component={Threats} />}
          />
          <Route
            path="/accounts/:accountId/threats"
            element={<AccountComponent component={Threats} />}
          />

          <Route
            path="/eligibility-quiz/:quizCategory"
            element={
              <AccountComponent
                component={EligibilityQuiz}
                redirectPath="/accounts"
              />
            }
          />
          <Route
            path="/accounts/:accountId/eligibility-quiz/:quizCategory"
            element={
              <AccountComponent
                component={EligibilityQuiz}
                redirectPath="/accounts"
              />
            }
          />

          <Route
            path="/accounts/:accountId/inbox"
            element={
              <AccountComponent component={Inbox} redirectPath="/accounts" />
            }
          />
          <Route
            path="/inbox"
            element={
              <AccountComponent component={Inbox} redirectPath="/accounts" />
            }
          />

          <Route
            path="/style-guide"
            element={<Navigate replace to="/design-system" />}
          />

          <Route
            path="/"
            element={
              isMultiAccount ? (
                <Navigate replace to="/accounts" />
              ) : (
                <ViewAccount />
              )
            }
          />
          <Route path="/accounts" element={<Accounts />} />
          <Route
            path="/accounts/:accountId"
            element={<AccountComponent component={ViewAccount} />}
          />

          <Route
            path="/accounts/:accountId/become-a-member"
            element={
              <AccountComponent
                component={BecomeAMember}
                redirectPath="/accounts"
              />
            }
          />
          <Route
            path="/become-a-member"
            element={
              <AccountComponent
                component={BecomeAMember}
                redirectPath="/accounts"
              />
            }
          />

          <Route
            path="/accounts/:accountId/advisor-signup"
            element={
              <AccountComponent
                component={AdvisorSignup}
                redirectPath="/accounts"
              />
            }
          />
          <Route
            path="/advisor-signup"
            element={
              <AccountComponent
                component={AdvisorSignup}
                redirectPath="/accounts"
              />
            }
          />

          <Route
            path="/accounts/:accountId/book-consultation"
            element={
              <AccountComponent
                component={BookConsultation}
                redirectPath="/accounts"
              />
            }
          />
          <Route
            path="/book-consultation"
            element={
              <AccountComponent
                component={BookConsultation}
                redirectPath="/accounts"
              />
            }
          />

          <Route
            path="/sylvia"
            element={
              barkAPIRootURL === "https://bark-api.ncx.com/" ? (
                <Navigate replace to="/" />
              ) : (
                <AccountComponent component={Sylvia} />
              )
            }
          />
        </Route>

        <Route path="*" Component={Error404} />
      </Route>

      <Route
        path="/accounts/:accountId/checkup/spb"
        element={
          <AccountComponent component={SPBWizard} redirectPath="/accounts" />
        }
      />

      <Route
        path="/checkup/spb"
        element={
          <AccountComponent component={SPBWizard} redirectPath="/accounts" />
        }
      />
    </SentryRoutes>
  )
}
