import { CSSProperties } from "react"
import { Disclosure, DisclosureStateReturn } from "reakit"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faXmark } from "@fortawesome/pro-duotone-svg-icons"

export interface SlideInNavCloseProps {
  disclosure: DisclosureStateReturn
}

const SlideInNavClose = ({ disclosure }: SlideInNavCloseProps) => (
  <div className="relative h-6 w-full">
    <div className="absolute -right-0.5 top-0 z-2147483641">
      <Disclosure
        {...disclosure}
        className="text-white hover:bg-brand-dark rounded-sm h-6 w-6"
        aria-label="Close menu"
      >
        <i
          aria-hidden="true"
          className="flex items-center justify-center h-6 w-6"
        >
          <FontAwesomeIcon
            icon={faXmark}
            style={
              {
                "--fa-primary-opacity": 0,
                "--fa-secondary-opacity": 1,
              } as CSSProperties
            }
          />
        </i>
      </Disclosure>
    </div>
  </div>
)

export default SlideInNavClose
