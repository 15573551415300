import cx from "classnames"

import { TableComponentTypes } from "./types"

const TableHeadCell = ({ className, children }: TableComponentTypes) => (
  <th className={cx("p-3 pb-3 font-semibold lg:p-5 lg:pb-4", className)}>
    {children}
  </th>
)

export default TableHeadCell
