import cx from "classnames"

import { TableComponentTypes } from "./types"

const TableBody = ({ className, children }: TableComponentTypes) => (
  <tbody className={cx("divide-y divide-cloud-500 text-carbon", className)}>
    {children}
  </tbody>
)

export default TableBody
