import { useEffect, useState } from "react"
import { useParams, Navigate, useLocation } from "react-router"
import { useQueryClient } from "@tanstack/react-query"

import Error404 from "./Error404"
import ProjectDetailsMain from "../sections/ProjectDetails/ProjectDetailsMain"
import useProjectDetailsData from "../hooks/useProjectDetailsData"
import { CalculatorInputsTypes, RoiTypes } from "@/types/roi"
import { TileURLsTypes } from "@/types/tiles"

const ProjectDetails = () => {
  const { projectId } = useParams()
  const queryClient = useQueryClient()
  const location = useLocation()
  const [roiCalcDefaults, setRoiCalcDefaults] =
    useState<CalculatorInputsTypes | null>(null)
  const [acceptInvite, setAcceptInvite] = useState<boolean | null>(null)

  const {
    projectsData,
    filteredProjectsData,
    projectData,
    similarPrograms,
    stackablePrograms,
    roiData,
    showRoi,
    invite,
    account,
    profile,
    property,
    tileData,
    parcelData,
    nonMemberParcelData,
    isLoading,
    isMapError,
    accountsData,
    queryStatus,
    tileIsLoading,
    parcelIsLoading,
    nonMemberParcelIsLoading,
    attestationsData,
    roiIsSuccess,
  } = useProjectDetailsData(queryClient, projectId)

  useEffect(() => {
    if (roiData && !roiCalcDefaults && roiIsSuccess) {
      setRoiCalcDefaults(roiData?.calculator_inputs)
    }
  }, [roiData, roiCalcDefaults, roiIsSuccess])

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "instant",
    })
  }, [projectId])

  useEffect(() => {
    if (location?.state?.acceptInvite) {
      setAcceptInvite(true)
    }
    if (location?.state?.acceptInvite === false) {
      setAcceptInvite(false)
    }

    return () => setAcceptInvite(null)
  }, [location?.state?.acceptInvite])

  // DEV: MA users with no accounts get an error, so we redirect them to the account creation page
  if (accountsData?.count === 0) {
    return <Navigate replace to="/accounts/new" />
  }

  if (queryStatus === "success" && projectData === undefined) {
    return <Error404 />
  }

  return (
    <ProjectDetailsMain
      isLoading={isLoading}
      isMapError={isMapError}
      projectsData={projectsData}
      profile={profile}
      projectData={projectData}
      attestationsData={attestationsData}
      account={account}
      similarPrograms={similarPrograms}
      stackablePrograms={stackablePrograms}
      acceptInvite={acceptInvite}
      setAcceptInvite={setAcceptInvite}
      invite={invite}
      filteredProjectsData={filteredProjectsData}
      roiData={roiData as RoiTypes}
      showRoi={showRoi}
      tileIsLoading={tileIsLoading}
      parcelIsLoading={
        account?.is_member ? parcelIsLoading : nonMemberParcelIsLoading
      }
      tileData={
        account?.is_member
          ? tileData
          : ({
              raster: { min: [], pref: [] },
              vector: "",
            } as unknown as TileURLsTypes)
      }
      parcelData={account?.is_member ? parcelData : nonMemberParcelData}
      property={property}
      roiCalcDefaults={roiCalcDefaults as CalculatorInputsTypes}
    />
  )
}

export default ProjectDetails
