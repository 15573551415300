import { useEffect, useRef } from "react"
import { Link, useSearchParams } from "react-router"
import { useQueryClient } from "@tanstack/react-query"
import cx from "classnames"

import SPBNavigation from "../sections/SPB/SPBNavigation"
import SPBContent from "../sections/SPB/SPBContent"
import {
  useAccount,
  useAccountId,
  useAccountInterest,
  useAccountProperty,
  useCreateMembership,
  useGetSpbTiles,
  useGetWizards,
  useIsMultiAccount,
  useProfile,
  useProjectList,
} from "../hooks"
import { useIsCheckoutSuccess } from "../hooks/useIsCheckoutSuccess"
import { useAccessToken } from "../stores"
import { useSPBStore } from "../stores/useSPBStore"
import { AccountInterestTypes } from "@/types/accountInterest"
import { WizardsType, TileUrlsType } from "@/types/wizards"
import { ProjectListTypes } from "@/types/program"
import { Profile } from "@/types"
import { AccountProperty } from "@/types/property"
import { AccountTypes } from "@/types/account"
import { SPB_TILE_MAPTYPES } from "../sections/SPB/constants"

const totalSteps = 5

const SPBWizard = () => {
  const [searchParams] = useSearchParams()
  const queryClient = useQueryClient()
  const accountId = useAccountId()
  const accessToken = useAccessToken()
  const isMultiAccount = useIsMultiAccount()
  const {
    activeStep,
    setActiveStep,
    setEnabledSteps,
    setSelectedActionGroup,
    setSelectedImplementation,
  } = useSPBStore()

  useIsCheckoutSuccess()

  const isLoggedIn = !!accessToken

  const queryEnabled =
    isLoggedIn &&
    ((isMultiAccount && accountId !== "_single") || !isMultiAccount)

  const { data: profile, isLoading: profileIsLoading } = useProfile<
    Profile,
    Error
  >(queryClient, {
    enabled: !!accessToken,
  })

  const { data: account, isLoading: accountIsLoading } = useAccount<
    AccountTypes,
    Error
  >(queryClient, accountId)

  const { data: property, isLoading: propertyIsLoading } = useAccountProperty<
    AccountProperty,
    Error
  >(queryClient, accountId, {
    refetchInterval: (query) => {
      if (query.state.data?.landcover_data === null) {
        return 1000
      }

      return false
    },
  })

  const { data: accountInterestData, isLoading: accountInterestIsLoading } =
    useAccountInterest<AccountInterestTypes, Error>(queryClient, accountId, {
      enabled: queryEnabled,
    })

  const { data: wizards, isLoading: wizardsIsLoading } = useGetWizards<
    WizardsType,
    Error
  >(queryClient, accountId)

  const { data: projectList, isLoading: projectListIsLoading } = useProjectList<
    ProjectListTypes,
    Error
  >(queryClient, accountId, {})

  const { data: spbOverallTileData, isLoading: spbOveralTileDataIsLoading } =
    useGetSpbTiles<TileUrlsType, Error>(accountId, SPB_TILE_MAPTYPES.overall)

  // fetch action map tiles separately but don't hold loading for them
  const { data: spbActionTileData, isLoading: spbActionMapsTileDataIsLoading } =
    useGetSpbTiles<TileUrlsType, Error>(accountId, SPB_TILE_MAPTYPES.actions)

  // Handle membership creation
  const membershipSuccessToken =
    searchParams.get("checkout-success")?.slice(2, -1) || ""

  const { status: membershipStatus } = useCreateMembership(
    accountId,
    membershipSuccessToken,
    {
      enabled: !!accountId && !!membershipSuccessToken,
    }
  )

  const createMembershipIsLoading =
    membershipSuccessToken && membershipStatus === "pending"

  const wizardData = wizards?.find((wizard) => wizard.key === "spb")

  const isLoadingArr = [
    accountInterestIsLoading,
    profileIsLoading,
    propertyIsLoading,
    wizardsIsLoading,
    projectListIsLoading,
    accountIsLoading,
    spbOveralTileDataIsLoading,
    createMembershipIsLoading,
  ]

  const isLoading = isLoadingArr.some(Boolean)

  useEffect(() => {
    if (wizardData?.completed) {
      if (wizardData?.selected_action_group === "spb_no_action") {
        setEnabledSteps([true, true, false, false, true])
      } else {
        setEnabledSteps([true, true, true, true, true])
      }
      setActiveStep(totalSteps - 1)
    }
  }, [
    setActiveStep,
    setEnabledSteps,
    wizardData?.completed,
    wizardData?.selected_action_group,
  ])

  useEffect(() => {
    if (wizardData?.selected_action_group) {
      setSelectedActionGroup(wizardData.selected_action_group)
    }
  }, [wizardData, setSelectedActionGroup])

  useEffect(() => {
    if (wizardData?.selected_implementation) {
      setSelectedImplementation(wizardData?.selected_implementation)
    }
  }, [wizardData, setSelectedImplementation])

  const completedRef = useRef(wizardData?.completed)

  useEffect(() => {
    const completed = completedRef.current

    return () => {
      if (!completed) {
        setEnabledSteps([true, false, false, false, false])
        setActiveStep(0)
        setSelectedActionGroup(null)
        setSelectedImplementation(null)
      }
    }
  }, [
    setActiveStep,
    setEnabledSteps,
    setSelectedActionGroup,
    setSelectedImplementation,
  ])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [activeStep])

  return (
    <div className="bg-white min-h-full">
      <div className="relative w-full flex lg:justify-center pt-5 lg:pt-10 px-5 lg:px-10">
        <SPBNavigation />

        <Link
          to="/"
          className={cx(
            "spb-close-button",
            "absolute right-5 xl:right-10 top-5 lg:top-10 flex items-center justify-center",
            "text-3xl w-6 h-6 text-dusk-500 leading-6 tracking-[0.02em] cursor-pointer"
          )}
          aria-label="Close SPB Wizard"
        >
          &times;
        </Link>
      </div>

      <SPBContent
        profile={profile}
        property={property}
        account={account}
        accountInterestData={accountInterestData}
        wizardData={wizardData}
        spbOverallMapTileData={spbOverallTileData}
        spbActionMapsTileData={spbActionTileData}
        spbActionMapsTileDataIsLoading={spbActionMapsTileDataIsLoading}
        isLoading={isLoading}
        projectList={projectList}
      />
    </div>
  )
}

export default SPBWizard
