import { Link } from "react-router"
import { useQueryClient } from "@tanstack/react-query"

import { Notification } from "../components/Notification"
import { buildAccountAbsoluteUrl, useDeleteGlobalNotification } from "../hooks"

export interface NotificationType {
  id: string
  account_id: string
  account_name: string
  key: string
}

export interface GlobalNotificationTypes {
  notification: NotificationType
}

const GlobalNotification = ({ notification }: GlobalNotificationTypes) => {
  const queryClient = useQueryClient()
  const accountId = notification.account_id
  const accountUrl = buildAccountAbsoluteUrl(accountId)

  const { mutate: deleteNotification } =
    useDeleteGlobalNotification(queryClient)
  const onDismiss = () => deleteNotification(notification.id)

  if (notification.key !== "added_to_account") {
    return null
  }

  return (
    <Notification variant="success" onDismiss={onDismiss}>
      You've been given access to a new account:{" "}
      <Link to={accountUrl} className="link">
        {notification.account_name}
      </Link>
    </Notification>
  )
}

export default GlobalNotification
