import { useState } from "react"
import { AxiosError } from "axios"
import { useNavigate } from "react-router"
import { useQueryClient } from "@tanstack/react-query"
import { useEffectOnce } from "react-use"

import ErrorCard from "../components/ErrorCard"
import { useLogout } from "../hooks"
import MainLayout from "./_layouts/Main"
import { genericErrMsg } from "../api/auth"
import { useSessionStore } from "../stores"

const Logout = () => {
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const { setIdentified } = useSessionStore((state) => state)

  // DEV: If there's an error, `data` will be `undefined`. On success, it will be filled
  const { mutateAsync: logout, isPending } = useLogout(queryClient, {
    onSuccess: () => {
      navigate("/getting-started")
    },
  })

  const handleLogout = async () => {
    try {
      await logout({})
      setIdentified(false)
    } catch (err) {
      if (err && (err as AxiosError).isAxiosError) {
        const axiosError = err as AxiosError<{ detail?: string }>
        setErrorMessage(axiosError?.response?.data?.detail || genericErrMsg)
      } else {
        setErrorMessage(genericErrMsg)
      }
      throw err
    }
  }
  useEffectOnce(() => {
    handleLogout()
  })

  return (
    <MainLayout contentBg="bg-gray-100" isLoading={[isPending]}>
      <div className="flex-auto container flex flex-row items-center justify-center">
        <ErrorCard>{errorMessage}</ErrorCard>
      </div>
    </MainLayout>
  )
}

export default Logout
