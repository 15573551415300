import { useMemo } from "react"
import { QueryClient } from "@tanstack/react-query"
import { FeatureCollection, Geometry, Properties } from "@turf/helpers"

import {
  useAccountUsers,
  useDashboardAccounts,
  useIsMultiAccount,
  useProfile,
  useAccountId,
  useProjectList,
  useAttestations,
  useAccount,
  useRoi,
  useStackables,
  useAccountProperty,
  useGetTileUrls,
  useGetParcelAssessment,
  useAccountNotifications,
  useGetParcelGeoms,
} from "./index"
import {
  filterProjectsData,
  getProjectData,
  getProjectsData,
  getStackableProjectsData,
} from "../shared/utils"
import {
  LANDOWNER_STATUS,
  NOTIFICATIONS,
  PROJECT_SORTING,
} from "../shared/constants"
import useLocalStorage from "./useLocalStorage"
import { useProjectsStore } from "../stores/useProjectsStore"
import { ProgramType, ProjectListTypes } from "@/types/program"
import { AccountProperty } from "@/types/property"
import { AccountTypes } from "@/types/account"
import { TileURLsTypes } from "@/types/tiles"
import { AttestationsType } from "@/types/attestations"
import { Profile } from "@/types"
import { DashboardAccountsTypes } from "@/types/dashboardAccounts"
import { RoiTypes } from "@/types/roi"
import { NotificationTypes } from "@/types/notifications"

const useProjectDetailsData = (
  queryClient: QueryClient,
  projectId: string | undefined
) => {
  const {
    category,
    govtProjectsOn,
    ineligibleProjectsOn,
    hasLandownerCost,
    termLengthFilter,
    paymentTypes,
    searchProjects,
  } = useProjectsStore()

  const accountId = useAccountId()
  const isMultiAccount = useIsMultiAccount()
  const [sortBy] = useLocalStorage(
    "sortProjectsBy",
    PROJECT_SORTING.RECENTLY_ADDED
  )

  /*** Queries ***/

  const {
    data,
    status: queryStatus,
    isLoading: queryIsLoading,
  } = useProjectList<ProjectListTypes, Error>(queryClient, accountId, {})

  const { data: stackableProjectsIds } = useStackables<number[], Error>(
    queryClient,
    accountId,
    projectId as string
  )

  const { data: property, isLoading: propertyIsLoading } = useAccountProperty<
    AccountProperty,
    Error
  >(queryClient, accountId)

  const { data: account, isLoading: accountIsLoading } = useAccount<
    AccountTypes,
    Error
  >(queryClient, accountId)

  const {
    data: tileData,
    isLoading: tileIsLoading,
    isError: tileIsError,
  } = useGetTileUrls<TileURLsTypes, Error>(accountId, projectId as string, {
    enabled: !!account?.is_member,
  })

  const {
    data: parcelData,
    isLoading: parcelIsLoading,
    isError: parcelIsError,
  } = useGetParcelAssessment<FeatureCollection<Geometry, Properties>, Error>(
    accountId,
    projectId as string,
    { enabled: !!account?.is_member }
  )

  const { data: nonMemberParcelData, isLoading: nonMemberParcelIsLoading } =
    useGetParcelGeoms<FeatureCollection<Geometry, Properties>, Error>(
      accountId,
      { enabled: account?.is_member === false }
    )

  const { data: attestationsData, isLoading: attestationsIsLoading } =
    useAttestations<AttestationsType, Error>(
      queryClient,
      accountId,
      projectId as string,
      {}
    )

  const { data: profile, isLoading: profileIsLoading } = useProfile<
    Profile,
    Error
  >(queryClient)

  const { isLoading: accountUsersIsLoading } = useAccountUsers(
    queryClient,
    "_single",
    {
      enabled: !isMultiAccount,
    }
  )

  const { data: accountsData, isLoading: accountsIsLoading } =
    useDashboardAccounts<DashboardAccountsTypes, Error>(queryClient, {
      enabled: isMultiAccount,
    })

  const { data: roiData, isSuccess: roiIsSuccess } = useRoi<RoiTypes, Error>(
    queryClient,
    accountId,
    projectId as string
  )

  const { data: notifications, isLoading: notificationsIsLoading } =
    useAccountNotifications<NotificationTypes[], Error>(queryClient, accountId)

  /*** Data constants ***/

  const projectsData: ProgramType[] = useMemo(
    () => getProjectsData(data),
    [data]
  )

  const filteredProjectsData: ProgramType[] = useMemo(
    () =>
      filterProjectsData(
        projectsData,
        sortBy,
        category,
        govtProjectsOn,
        ineligibleProjectsOn,
        hasLandownerCost,
        termLengthFilter,
        paymentTypes,
        searchProjects
      ),
    [
      category,
      govtProjectsOn,
      hasLandownerCost,
      ineligibleProjectsOn,
      paymentTypes,
      projectsData,
      searchProjects,
      sortBy,
      termLengthFilter,
    ]
  )

  const projectData: ProgramType | undefined = useMemo(
    () => getProjectData(data, projectsData, Number(projectId)),
    [data, projectsData, projectId]
  )

  const similarPrograms: ProgramType[] = useMemo(
    () =>
      filteredProjectsData?.filter(
        (project) =>
          project.type === projectData?.type &&
          project.id !== projectData?.id &&
          [
            LANDOWNER_STATUS.eligible,
            LANDOWNER_STATUS.information_needed,
          ].includes(project.badge_display)
      ),
    [filteredProjectsData, projectData]
  )

  const stackablePrograms = useMemo(
    () => getStackableProjectsData(stackableProjectsIds, projectsData),
    [stackableProjectsIds, projectsData]
  ) as ProgramType[]

  const showRoi: boolean = useMemo(
    () =>
      typeof roiData === "object" &&
      [
        "total_revenue",
        "total_profit",
        "yearly_revenue",
        "yearly_cost",
        "yearly_profit",
        "yearly_npv",
      ].every((key) => key in roiData),
    [roiData]
  )

  const invite: NotificationTypes | undefined = useMemo(
    () =>
      notifications?.find(
        (notification) =>
          notification.key === NOTIFICATIONS.pd_invite &&
          notification?.extra_fields?.project_id === Number(projectId)
      ),
    [notifications, projectId]
  )

  const isMapError = tileIsError || parcelIsError

  const isLoading = [
    profileIsLoading,
    accountsIsLoading,
    accountUsersIsLoading,
    queryIsLoading,
    attestationsIsLoading,
    accountIsLoading,
    propertyIsLoading,
    notificationsIsLoading,
  ]

  return {
    projectsData,
    filteredProjectsData,
    projectData,
    similarPrograms,
    stackablePrograms,
    roiData,
    showRoi,
    invite,
    account,
    profile,
    property,
    tileData,
    parcelData: account?.is_member ? parcelData : nonMemberParcelData,
    nonMemberParcelData,
    isLoading,
    isMapError,
    accountsData,
    queryStatus,
    tileIsLoading,
    parcelIsLoading,
    nonMemberParcelIsLoading,
    attestationsData,
    roiIsSuccess,
  }
}

export default useProjectDetailsData
