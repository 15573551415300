import { NoRecordsRowTypes } from "./types"

const NoRecordsRow = ({ columns, noDataText }: NoRecordsRowTypes) => (
  <tr>
    <td
      className="py-4 text-center text-sm leading-[1.50] tracking-[0.01em]"
      colSpan={Object.keys(columns).length}
    >
      {noDataText}
    </td>
  </tr>
)

export default NoRecordsRow
