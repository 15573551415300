import cx from "classnames"

import { TableContainerTypes } from "./types"

const TableContainer = ({
  bordered = true,
  className,
  ref,
  children,
}: TableContainerTypes) => (
  <div
    className={cx(
      "overflow-x-scroll  md:overflow-x-auto",
      bordered && "border border-cloud-500 rounded-sm",
      className
    )}
    ref={ref}
  >
    <table className="min-w-max-content md:min-w-full divide-y divide-cloud-500 w-full">
      {children}
    </table>
  </div>
)

export default TableContainer
