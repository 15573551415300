import { Link } from "react-router"
import { useMenuState, Menu, MenuItem, MenuButton } from "reakit/Menu"

import UserMenuNonMemberLabel from "./UserMenuNonMemberLabel"
import UserMenuChevron from "./UserMenuChevron"
import UserMenuName from "./UserMenuName"
import UserMenuItems from "./UserMenuItems"
import { usePlatformUserMenuNames } from "./helpers"
import useMediaQuery from "../../hooks/useMediaQuery"
import { useIsMember } from "../../hooks/useIsMember"
import { useAccessToken } from "../../stores"

export interface UserMenuProps {
  isPartnersNav: boolean
}

const UserMenu = ({ isPartnersNav }: UserMenuProps) => {
  const menu = useMenuState()
  const accessToken = useAccessToken()
  const isMember = useIsMember()
  const { fullName, abbreviatedName } = usePlatformUserMenuNames()
  const isMaxWidthSm = useMediaQuery("(max-width: 663px)")

  const isLoggedIn = !!accessToken

  if (!isLoggedIn) {
    return null
  }

  return (
    <>
      <MenuButton
        // {/* DEV: `overflow-hidden` is required for inner `truncate` to function */}
        className="relative flex items-start hover:cursor-pointer overflow-hidden text-white text-base leading-[1.50] tracking-[0.02em] px-2 py-4 ml-1 md:ml-3"
        data-test="menu-button"
        {...menu}
      >
        <UserMenuName
          name={isMaxWidthSm ? abbreviatedName : fullName}
          isMember={isMember}
          isPartnersNav={isPartnersNav}
        />

        <UserMenuChevron isPartnersNav={isPartnersNav} isMember={isMember} />

        <UserMenuNonMemberLabel
          isPartnersNav={isPartnersNav}
          isMember={isMember}
        />
      </MenuButton>

      <Menu
        className="py-1 rounded-md bg-white ring-1 ring-black/5 min-w-32 z-2147483641"
        {...menu}
        aria-label="User Action Menu"
      >
        <UserMenuItems isPartnersNav={isPartnersNav} menu={menu} />

        <MenuItem
          className="block px-4 py-2 text-sm leading-[1.50] tracking-[0.01em] text-gray-700 focus:ring-0 focus:underline link--underline-only"
          {...menu}
          as={Link}
          to="/logout"
          data-test="logout"
        >
          Logout
        </MenuItem>
      </Menu>
    </>
  )
}

export default UserMenu
