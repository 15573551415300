import { useLocation, useNavigate } from "react-router"
import { useQueryClient } from "@tanstack/react-query"

import MainLayout from "./_layouts/Main"
import { Spinner } from "../components/Spinner"
import AdvisorSignupPay from "../sections/AdvisorSignup/AdvisorSignupPay"
import AdvisorySignupTestimonials from "../sections/AdvisorSignup/AdvisorySignupTestimonials"
import AdvisorySignupSamples from "../sections/AdvisorSignup/AdvisorySignupSamples"
import { useAccount, useAccountId, useAccountUrlPrefix } from "../hooks"

const AdvisorSignup = () => {
  const accountId = useAccountId()
  const accountUrlPrefix = useAccountUrlPrefix()
  const queryClient = useQueryClient()
  const location = useLocation()
  const navigate = useNavigate()

  const { data: account, isLoading: accountIsLoading } = useAccount(
    queryClient,
    accountId
  )

  if (account?.is_member === false) {
    navigate(`${accountUrlPrefix}/become-a-member`, {
      state: { includeAdvisor: true, prevPath: location.pathname },
      replace: true,
    })

    return (
      <div className="flex-auto flex items-center justify-center">
        <Spinner />
      </div>
    )
  }
  return (
    <MainLayout isLoading={[accountIsLoading]} contentBg="bg-white">
      <div className="container px-4 pt-[92px] pb-[132px]">
        <h1 className="text-4xl md:text-[50px] text-charcoal-500 text-center font-medium leading-[124%] md:leading-[110%] tracking-[0.4px] md:tracking-[1px]">
          Your land. Your goals. Expert plan.
        </h1>

        <AdvisorSignupPay />

        <AdvisorySignupTestimonials />

        <AdvisorySignupSamples />
      </div>
    </MainLayout>
  )
}

export default AdvisorSignup
