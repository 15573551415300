import assert from "assert"
import cx from "classnames"

import TableContainer from "./TableContainer"
import NoRecordsRow from "./NoRecordsRow"
import TableHeadRow from "./TableHeadRow"
import TableHeadCell from "./TableHeadCell"
import TableBody from "./TableBody"
import TableRow from "./TableRow"
import TableCell from "./TableCell"
import TableFoot from "./TableFoot"
import { RowDataTypes, TableTypes } from "./types"

const Table = ({
  bordered = true,
  className,
  columns,
  data,
  footer = null,
  isHeaderVisible = true,
  noDataText = "No records found",
  onRowClick = () => {},
  rowClassName = "",
  ref,
}: TableTypes) => {
  assert(
    ["string", "function"].includes(typeof rowClassName),
    "`rowClassName` must be string or function whose return value will be passed to `cx`"
  )

  return (
    <TableContainer ref={ref} className={className} bordered={bordered}>
      {isHeaderVisible && (
        <TableHeadRow>
          {Object.entries(columns).map(([id, column]) => (
            <TableHeadCell className={column.headerClassName} key={id}>
              {column.title}
            </TableHeadCell>
          ))}
        </TableHeadRow>
      )}

      <TableBody>
        {data.length > 0 ? (
          data.map((row: RowDataTypes, index: number) => (
            <TableRow
              className={cx(
                typeof rowClassName === "function"
                  ? rowClassName(row)
                  : rowClassName
              )}
              key={`row-${row.id || index}`}
              onClick={() => onRowClick(row)}
            >
              {Object.entries(columns).map(([id, column]) => (
                <TableCell
                  className={cx(
                    typeof column.className === "function"
                      ? column.className(row)
                      : column.className
                  )}
                  key={`${row.id || index}-${id}`}
                >
                  {column.getValue(row)}
                </TableCell>
              ))}
            </TableRow>
          ))
        ) : (
          <NoRecordsRow columns={columns} noDataText={noDataText} />
        )}
      </TableBody>

      {footer && <TableFoot>{footer}</TableFoot>}
    </TableContainer>
  )
}

export default Table
