import { useEffect, useState } from "react"
import { useNavigate, useLocation } from "react-router"
import { useQueryClient } from "@tanstack/react-query"
import { useDialogState } from "reakit/Dialog"

import MainLayout from "../_layouts/Main"
import Property from "../../sections/Property"
import {
  useAccountId,
  useAccountProperty,
  useAccountUrlPrefix,
} from "../../hooks"
import { AccountProperty } from "../../types/property"
import useLocalStorage from "../../hooks/useLocalStorage"
import { calcAcres } from "../../utils"
import { FeatureType } from "@/types"

const OnboardingPropertyBoundaries = () => {
  // Define our hooks
  const accountId = useAccountId()
  const navigate = useNavigate()
  const location = useLocation()
  const accountUrlPrefix = useAccountUrlPrefix()
  const queryClient = useQueryClient()
  const uploadDialog = useDialogState({ animated: true })
  const [featureStorage, setFeatureStorage] = useLocalStorage<FeatureType[]>(
    "storedFeatures",
    []
  )
  const [initialAssessmentAcreage, setInitialAssessmentAcreage] = useState<
    number | undefined
  >(undefined)
  const [storageCleared, setStorageCleared] = useState(false)

  const { data: property, isLoading: propertyIsLoading } = useAccountProperty<
    AccountProperty,
    Error
  >(queryClient, accountId)

  const handleSuccess = () => {
    // clear the stored features from local storage after the property is saved
    setFeatureStorage([])
    setStorageCleared(true) // Set this to indicate that the storage is cleared
  }

  const initialAssessmentFeatures =
    featureStorage.length > 0 ? featureStorage : undefined

  useEffect(() => {
    if (initialAssessmentFeatures) {
      const calculatedAcreage = calcAcres(initialAssessmentFeatures)
      setInitialAssessmentAcreage(calculatedAcreage)
    }
  }, [initialAssessmentFeatures]) // Recalculate acreage when features change

  useEffect(() => {
    if (storageCleared) {
      // If indication that the handleSuccess function has been called
      if (location?.state?.hasCompletedSurvey) {
        navigate(`${accountUrlPrefix}/onboarding/report`, {
          state: { refreshPropertyBoundaries: true },
        })
      } else {
        navigate(`${accountUrlPrefix}/onboarding/goals`)
      }
    }
  }, [
    storageCleared,
    location?.state?.hasCompletedSurvey,
    accountUrlPrefix,
    navigate,
  ])

  return (
    <MainLayout isLoading={[propertyIsLoading]} contentBg="bg-white">
      {property && (
        <Property
          property={property}
          hidden={false}
          submitText="Save and Continue"
          onSuccess={handleSuccess}
          uploadDialog={uploadDialog}
          initialAssessmentFeatures={initialAssessmentFeatures}
          initialAssessmentAcreage={initialAssessmentAcreage}
        />
      )}
    </MainLayout>
  )
}

export default OnboardingPropertyBoundaries
