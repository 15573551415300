import { useQueryClient } from "@tanstack/react-query"

import MainLayout from "./_layouts/Main"
import ThreatsHero from "../sections/Threats/ThreatsHero"
import ThreatsHeader from "../sections/Threats/ThreatsHeader"
import ThreatsStats from "../sections/Threats/ThreatsStats"
import ThreatsMap from "../sections/Threats/ThreatsMap"
import ThreatsText from "../sections/Threats/ThreatsText"
import {
  useAccountId,
  useAccount,
  useAccountProperty,
  useProfile,
  useChangeDetection,
} from "../hooks"
import { Profile } from "@/types"
import { AccountProperty } from "@/types/property"
import { AccountTypes } from "@/types/account"

import { TileURLsTypes } from "@/types/tiles"

const Threats = () => {
  const accountId = useAccountId()
  const queryClient = useQueryClient()

  const { data: profile, isLoading: profileIsLoading } = useProfile<
    Profile,
    Error
  >(queryClient)

  const { data: property, isLoading: propertyIsLoading } = useAccountProperty<
    AccountProperty,
    Error
  >(queryClient, accountId)

  const { data: account, isLoading: accountIsLoading } = useAccount<
    AccountTypes,
    Error
  >(queryClient, accountId)

  const { data: changeDetectionData, isLoading: changeDetectionIsLoading } =
    useChangeDetection<TileURLsTypes, Error>(queryClient, accountId)

  return (
    <MainLayout
      contentBg="bg-white"
      isLoading={[
        profileIsLoading,
        propertyIsLoading,
        accountIsLoading,
        changeDetectionIsLoading,
      ]}
    >
      <ThreatsHero />

      <div className="container max-w-[1440px] pb-[55px]">
        <ThreatsHeader
          firstName={profile?.first_name}
          acreage={property?.acreage}
        />

        <ThreatsStats
          property={property}
          isMember={account?.is_member}
          date={changeDetectionData?.date}
        />

        <div className="xl:flex xl:justify-between gap-[88px]">
          <ThreatsMap
            changeData={property?.change_data}
            changeDetectionData={changeDetectionData}
            isMember={account?.is_member}
          />

          <ThreatsText />
        </div>
      </div>
    </MainLayout>
  )
}

export default Threats
