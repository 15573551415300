import { useEffect, useMemo, useState } from "react"
import { useQueryClient } from "@tanstack/react-query"
import isEmpty from "lodash/isEmpty"

import { ProgressBar } from "../../components/ProgressBar"
import { Toast } from "../../components/Toast"
import MainLayout from "../_layouts/Main"
import OnboardingOverviewSteps from "../../sections/Onboarding/OnboardingOverview/OnboardingOverviewSteps"
import {
  useAccountId,
  useGetCompare,
  useGetOnboardingRecs,
  useProjectList,
} from "../../hooks"
import { getProjectsData } from "../../shared/utils"
import { genericErrMsg } from "../../api/auth"
import { ProgramCategoryType } from "../../types/constants"
import { ProjectListTypes, ProjectDataTypes } from "@/types/program"
import { OnboardingRecsType } from "@/types/onboardingRecs"

const OnboardingOverview = () => {
  const [overviewStep, setOverviewStep] = useState<number>(1)
  const [selectedCategory, setSelectedCategory] =
    useState<ProgramCategoryType | null>(null)

  const queryClient = useQueryClient()
  const accountId = useAccountId()

  const { data: onboardingRecs, isLoading: onboardingRecsIsLoading } =
    useGetOnboardingRecs<OnboardingRecsType, Error>(queryClient, accountId, {
      refetchInterval: (query) => {
        if (isEmpty(query.state.data)) {
          return 1000
        }

        return false
      },
    })

  const { data: projectList, isLoading: projectListIsLoading } = useProjectList<
    ProjectListTypes,
    Error
  >(queryClient, accountId)

  const programsData: ProjectDataTypes[] = useMemo(() => {
    return getProjectsData(projectList)
  }, [projectList])

  const {
    data: compareData,
    mutateAsync: getCompare,
    isPending: isGetComparePending,
  } = useGetCompare(queryClient, accountId, true, {
    onError: (error: Error) => {
      Toast.error(error?.message || genericErrMsg)
    },
  })

  const handleGetCompare = async () => {
    const programIds: number[] = onboardingRecs
      ? onboardingRecs[selectedCategory as ProgramCategoryType].projects.map(
          (program) => program.id
        )
      : []

    setOverviewStep(6)

    await getCompare(programIds)
  }

  const handleOverviewStep = (newStep?: number) => {
    if (newStep) {
      setOverviewStep(newStep)
    } else {
      setOverviewStep((oldStep) => oldStep + 1)
    }
  }

  const handleSetSelectedCategory = (category: ProgramCategoryType) => {
    setSelectedCategory(category)
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [overviewStep])

  return (
    <MainLayout
      isLoading={[projectListIsLoading, onboardingRecsIsLoading]}
      contentBg="bg-white"
      className="onboarding"
    >
      <ProgressBar
        className="onboarding-survey-progress-bar w-full h-5 z-[2]"
        value={overviewStep}
        max={6}
      />

      <OnboardingOverviewSteps
        overviewStep={overviewStep}
        handleOverviewStep={handleOverviewStep}
        onboardingRecs={onboardingRecs}
        programsData={programsData}
        selectedCategory={selectedCategory}
        handleSetSelectedCategory={handleSetSelectedCategory}
        compareData={compareData}
        handleGetCompare={() => {
          handleGetCompare()
        }}
        isGetComparePending={isGetComparePending}
      />
    </MainLayout>
  )
}

export default OnboardingOverview
