import { useRef } from "react"
import { useQueryClient } from "@tanstack/react-query"
import {
  Geometry,
  GeometryCollection,
  Properties,
  Feature,
} from "@turf/helpers"
import MapGL from "@urbica/react-map-gl"
import { Spinner } from "../components/Spinner"

import TeaserMapSidebar from "../sections/TeaserMap/TeaserMapSidebar"
import TeaserMapFooter from "../sections/TeaserMap/TeaserMapFooter"
import { MapVisualization } from "../components/MapVisualization/MapVisualization"
import { useTeaserAssessment } from "../hooks"
import {
  useFeatureHandlers,
  useResolveViewport,
} from "../sections/TeaserMap/hooks"
import { MapGLRef } from "@/components/MapVisualization/types"

export type FeatureProperties = Feature<
  Geometry | GeometryCollection,
  Properties
>
export type FeaturesProperties = FeatureProperties[]

type Assessment = { category: string; n_programs: number }

interface TeaserMapProps {
  initialFeatures?: Feature<Geometry | GeometryCollection, Properties>[]
}

const TeaserMap = ({ initialFeatures = [] }: TeaserMapProps) => {
  const mapRef = useRef<MapGL>(null)
  const queryClient = useQueryClient()

  const { viewport, setViewport, viewportResolved, decodedAddress } =
    useResolveViewport(initialFeatures)

  const {
    formik,
    acreage,
    parcelIds,
    handleCreateFeatures,
    handleUpdateFeatures,
    handleDeleteFeatures,
    handleClearFeatures,
  } = useFeatureHandlers(initialFeatures)

  // Fetch the assessment data
  const shouldFetchAssessment =
    formik.dirty && formik.values.features.length !== 0

  const { data: assessmentData, status: assessmentDataStatus } =
    useTeaserAssessment(
      queryClient,
      {
        features: formik.values.features,
      },
      {
        enabled: shouldFetchAssessment,
      }
    )

  let assessmentDataReturn: Assessment[] =
    (assessmentData as Assessment[]) || []

  if (shouldFetchAssessment) {
    assessmentDataReturn = assessmentData as Assessment[]
  }

  const assessmentStatus = shouldFetchAssessment ? assessmentDataStatus : "idle"

  if (!viewportResolved) {
    return (
      <div className="flex flex-auto items-center justify-center h-full">
        <Spinner />
      </div>
    )
  }

  return (
    <div className="bg-white h-screen w-full absolute inset-0 z-10 xl:grid xl:grid-cols-[395px_1fr] xl:grid-rows-[1fr_84px] [&_.map-vizualization]:w-full [&_.map-vizualization]:h-full">
      <TeaserMapSidebar
        acreage={acreage}
        assessmentData={assessmentDataReturn || []}
        assessmentStatus={assessmentStatus}
        parcelIds={parcelIds}
        onSubmit={formik.handleSubmit}
      />

      <MapVisualization
        ref={mapRef as MapGLRef}
        features={formik.values.features}
        viewport={viewport}
        setViewport={setViewport}
        onViewportChange={setViewport}
        onFeatureCreate={handleCreateFeatures}
        onFeatureUpdate={handleUpdateFeatures}
        onFeatureDelete={handleDeleteFeatures}
        onFeatureClear={handleClearFeatures}
        showParcelSelect={true}
        showParcelDraw={false}
        showParcelUpload={false}
        decodedAddress={decodedAddress}
        displayMapEditingTools={true}
      />

      <TeaserMapFooter
        showAcreageSpinner={false}
        acreage={acreage}
        onSubmit={formik.handleSubmit}
      />
    </div>
  )
}

export default TeaserMap
