import { useState, useEffect } from "react"
import { UseMutateAsyncFunction, useQueryClient } from "@tanstack/react-query"

import MainLayout from "./_layouts/Main"
import MessagesThread from "../sections/Inbox/MessagesThread"
import InboxModal from "../sections/Inbox/InboxModal"
import MessagesTabs from "../sections/Inbox/MessagesTabs"
import useLocalStorage from "../hooks/useLocalStorage"
import {
  useAccount,
  useAccountId,
  useAccountNotifications,
  useAttestations,
  useProfile,
  useProjectList,
  useViewNotification,
} from "../hooks"
import { getProjectsData } from "../shared/utils"
import { NOTIFICATIONS } from "../shared/constants"
import {
  NotificationTypes,
  PDInviteExtraFieldsTypes,
} from "@/types/notifications"
import { ProjectDataTypes, ProjectListTypes } from "@/types/program"
import { Profile } from "@/types"
import { AccountTypes } from "@/types/account"
import { AttestationsType } from "@/types/attestations"

export type MessageType = "All Messages" | "Read Messages"
export type ActiveViewType = "tabs" | "thread"

const Inbox = () => {
  const accountId = useAccountId()
  const queryClient = useQueryClient()
  const [messageType, setMessageType] = useState<MessageType>("All Messages")
  const [activeTab, setActiveTab] = useState<string | number | null>(null)
  const [activeView, setActiveView] = useState<ActiveViewType>("tabs")
  const [showDialog, setShowDialog] = useLocalStorage<boolean>(
    "showInboxDialog",
    true
  )

  const { data: profile, isLoading: profileIsLoading } = useProfile<
    Profile,
    Error
  >(queryClient)

  const { data: account, isLoading: accountIsLoading } = useAccount<
    AccountTypes,
    Error
  >(queryClient, accountId)

  const { data: projectsList, isLoading: projectListIsLoading } =
    useProjectList<ProjectListTypes, Error>(queryClient, accountId, {})

  const projectsData: ProjectDataTypes[] = getProjectsData(projectsList) || []

  const { mutateAsync: viewNotification } = useViewNotification(
    queryClient,
    accountId
  )

  const {
    data: notifications,
    isLoading: notificationsIsLoading,
    isSuccess: notificationsIsSuccess,
  } = useAccountNotifications<NotificationTypes[], Error>(
    queryClient,
    accountId
  )

  useEffect(() => {
    const handleNotification = async () => {
      if (notificationsIsSuccess) {
        const invitesData: NotificationTypes[] = notifications.filter(
          (notification: NotificationTypes) =>
            notification.key === NOTIFICATIONS.pd_invite ||
            notification.key === NOTIFICATIONS.change_detected
        )

        if (invitesData.length > 0 && activeTab === null) {
          const topMessage: NotificationTypes =
            invitesData[invitesData.length - 1]
          setActiveTab(topMessage.id)

          if (topMessage.viewed_at === null) {
            await viewNotification(topMessage.id.toString())
          }
        }
      }
    }

    handleNotification()
  }, [notifications, activeTab, notificationsIsSuccess, viewNotification])

  const messages: NotificationTypes[] | undefined = notifications
    ?.filter(
      (notification: NotificationTypes) =>
        notification.key === NOTIFICATIONS.pd_invite ||
        notification.key === NOTIFICATIONS.change_detected
    )
    ?.reverse()
    ?.reduce((acc: NotificationTypes[], notification: NotificationTypes) => {
      const project = projectsData.find(
        (project: ProjectDataTypes) =>
          project.id === notification?.extra_fields?.project_id
      )

      if (project) {
        acc.push({
          ...notification,
          extra_fields: {
            ...notification.extra_fields,
            image_url: project.image_url,
            type: project.type,
            term: project.term,
            lo_cost: project.lo_cost,
            potential_earnings: project.potential_earnings,
            landowner_status: project.landowner_status,
            badge_display: project.badge_display,
          } as PDInviteExtraFieldsTypes,
        })
      } else {
        acc.push({
          ...notification,
        })
      }

      return acc
    }, [])

  const activeThread: NotificationTypes | undefined = messages?.find(
    (message) => message.id === activeTab
  )

  const { data: attestationsData, status: attestationsStatus } =
    useAttestations<AttestationsType, Error>(
      queryClient,
      accountId,
      (
        activeThread?.extra_fields as PDInviteExtraFieldsTypes
      )?.project_id?.toString(),
      {
        enabled: activeThread !== undefined,
      }
    )

  const activeMessages: NotificationTypes[] | undefined = messages?.filter(
    (message) => {
      if (messageType === "Read Messages") {
        return message?.viewed_at !== null && message?.viewed_at !== undefined
      }
      return message
    }
  )

  useEffect(() => {
    if (activeMessages && activeMessages.length > 0 && activeTab !== null) {
      setActiveTab(activeMessages[0].id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messageType])

  const onCloseDialog = () => {
    setShowDialog(false)
  }

  return (
    <MainLayout
      isLoading={[
        notificationsIsLoading,
        profileIsLoading,
        projectListIsLoading,
        accountIsLoading,
      ]}
    >
      <div className="container xl:max-w-[976px] px-4 pt-12 pb-[132px]">
        <div className="lg:flex bg-white">
          <MessagesTabs
            activeView={activeView}
            messages={messages}
            messageType={messageType}
            setMessageType={setMessageType}
            activeMessages={activeMessages}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            setActiveView={setActiveView}
            viewNotification={
              viewNotification as UseMutateAsyncFunction<
                unknown,
                Error,
                unknown,
                unknown
              >
            }
          />

          <MessagesThread
            profile={profile}
            messages={messages}
            activeView={activeView}
            setActiveView={setActiveView}
            activeTab={activeTab}
            activeThread={activeThread}
            account={account}
            attestationsData={attestationsData}
            attestationsStatus={attestationsStatus}
          />
        </div>
      </div>

      <InboxModal showDialog={showDialog} onCloseDialog={onCloseDialog} />
    </MainLayout>
  )
}

export default Inbox
