import { useEffect, useRef } from "react"
import { useParams, Navigate, useLocation } from "react-router"
import { useQueryClient } from "@tanstack/react-query"

import MainLayout from "./_layouts/Main"
import ProjectListingSkeleton from "../sections/ProjectListing/ProjectListingSkeleton"
import ProjectListingContent from "../sections/ProjectListing/ProjectListingContent"
import {
  useAccount,
  useAccountId,
  useAccountUsers,
  useDashboardAccounts,
  useIsMultiAccount,
  useProfile,
  useQueryParam,
} from "../hooks"
import useProjectListingData from "../hooks/useProjectListingData"
import useInvitesData from "../hooks/useInvitesData"
import useQuizCategory from "../hooks/useQuizCategory"
import useLocalStorage from "../hooks/useLocalStorage"
import { PROJECT_SORTING, PROJECT_SORTING_QS } from "../shared/constants"
import { useProjectsStore } from "../stores/useProjectsStore"
import { AccountUsers } from "../types/accountUsers"
import { Profile } from "../types"
import { AccountTypes } from "@/types/account"
import { DropdownOptionsType } from "../sections/ProjectListing/types"
import { OptionType } from "@/components/SelectMenu"
import { ProgramCategoryType } from "@/types/constants"

const dropdownOptions: DropdownOptionsType[] = [
  PROJECT_SORTING.RECENTLY_ADDED as DropdownOptionsType,
  PROJECT_SORTING.MOST_POPULAR as DropdownOptionsType,
  PROJECT_SORTING.VERIFIED as DropdownOptionsType,
  PROJECT_SORTING.ELIGIBILITY as DropdownOptionsType,
  PROJECT_SORTING.ENROLLMENT_DEADLINE as DropdownOptionsType,
  PROJECT_SORTING.POTENTIAL_EARNINGS as DropdownOptionsType,
]

export const ProjectListing = () => {
  const location = useLocation()
  const isMultiAccount = useIsMultiAccount()
  const queryClient = useQueryClient()
  const listingRef = useRef<HTMLDivElement | null>(null)
  const accountId = useAccountId()
  const { pageId } = useParams()
  const sortByOverride = useQueryParam("sortBy")
  const categoryOverride = useQueryParam("category") as
    | ProgramCategoryType
    | "saved_programs"
  const keywordOverride = useQueryParam("keyword")
  const [sortBy, setSortBy] = useLocalStorage<string>(
    "sortProjectsBy",
    PROJECT_SORTING.RECENTLY_ADDED
  )
  const { setCategory, setSearchProjects } = useProjectsStore()

  const handleSortBy: (val: OptionType) => void = (value: OptionType) => {
    setSortBy(value as string)
  }

  const {
    data,
    projectListIsLoading,
    projectsData,
    projectsDataIneligibleOnLength,
    projectTypeFilters,
    projectTypes,
  } = useProjectListingData(sortBy, accountId, queryClient)

  const { invitesData, notificationsIsLoading } = useInvitesData(
    data,
    accountId,
    queryClient
  )

  const { quizesData, quizCategory, quizesIsLoading } = useQuizCategory(
    accountId,
    location
  )

  const { data: profile, isLoading: profileIsLoading } = useProfile<
    Profile,
    Error
  >(queryClient)

  const { isLoading: accountUsersIsLoading } = useAccountUsers<
    AccountUsers,
    Error
  >(queryClient, "_single", {
    enabled: !isMultiAccount,
  })

  const { data: accountsData, isLoading: accountsIsLoading } =
    useDashboardAccounts(queryClient, {
      enabled: isMultiAccount,
    })

  const { data: account, isLoading: accountIsLoading } = useAccount<
    AccountTypes,
    Error
  >(queryClient, accountId)

  // DEV: Automatically Scroll to top of the listing container when navigating between the pages by using the bottom navigation
  useEffect(() => {
    if (
      listingRef &&
      listingRef?.current &&
      window.scrollY > listingRef?.current?.offsetTop
    ) {
      listingRef?.current?.scrollIntoView({ behavior: "instant" })
    }
  }, [pageId])

  useEffect(() => {
    const sort =
      PROJECT_SORTING_QS[sortByOverride as keyof typeof PROJECT_SORTING_QS]

    if (sort) {
      setSortBy(sort)
    }

    if (
      categoryOverride &&
      (categoryOverride === "saved_programs" ||
        projectTypes?.project_types?.includes(categoryOverride))
    ) {
      setCategory(categoryOverride)
    }

    if (keywordOverride) {
      setSearchProjects(keywordOverride)
    }
  }, [
    categoryOverride,
    keywordOverride,
    projectTypes?.project_types,
    setCategory,
    setSearchProjects,
    setSortBy,
    sortByOverride,
  ])

  // DEV: MA users with no accounts get an error, so we redirect them to the account creation page
  if (accountsData?.count === 0) {
    return <Navigate replace to="/accounts/new" />
  }

  return (
    <MainLayout
      isLoading={[
        profileIsLoading,
        accountsIsLoading,
        accountUsersIsLoading,
        projectListIsLoading,
        notificationsIsLoading,
        quizesIsLoading,
        accountIsLoading,
      ]}
      contentBg="bg-white"
      loader={<ProjectListingSkeleton />}
    >
      <ProjectListingContent
        data={data}
        profile={profile}
        account={account}
        projectsData={projectsData}
        invitesData={invitesData}
        projectTypeFilters={projectTypeFilters}
        dropdownOptions={dropdownOptions}
        quizesData={quizesData}
        quizCategory={quizCategory}
        projectsDataIneligibleOnLength={projectsDataIneligibleOnLength}
        pageId={pageId}
        listingRef={listingRef}
        sortBy={sortBy}
        handleSortBy={handleSortBy}
      />
    </MainLayout>
  )
}

export default ProjectListing
